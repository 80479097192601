<template>
  <div class="container" ref="wrapper">
    <van-sticky>
      <van-nav-bar
        title="签到统计"
        left-arrow
        @click-left="$router.replace({ path: '/' })"
      >
      </van-nav-bar>
    </van-sticky>

  
    <van-row>


          
          <van-cell title="选择单个日期" :value="date" @click="showcalendar = true" />
<van-calendar v-model="showcalendar"  :min-date="new Date(2022,4,10)" @confirm="onConfirm" />
          <ve-table
            id="tb"
            :columns="columns"
            :table-data="tableData"
            :expand-option="expandOption"
            row-key-field-name="name"
            max-height="calc(100vh - 192px)"
          />
  <child-vetable v-if="false"></child-vetable>
    </van-row>


  </div>
</template>

<script>
import Vue from "vue";
import "vue-easytable/libs/theme-default/index.css";
import { Toast } from "vant";
import ChildVetable from "./components/childvetable";
import { VeTable, VeLoading } from "vue-easytable";
import { getDepartmentData } from "@/api/workSignin";


Vue.use(VeTable);
Vue.use(VeLoading);

export default {
  data() {
    return {
        
      scroll: 0,
      date:"",
     showcalendar:false,
    loadingInstance: null,
      expandOption: {
          
        render: ({ row }) => {
            console.log(row);
          return (
            <child-vetable
              row={row}
              timeRange={this.date}
              on-beforeLoadData={this.showLoadingTable}
              on-finishedLoadData={this.hideLoadingTable}
             
            />
          );
        },
      },
      columns: [
        {
          field: "",
          key: "icon",
          // 设置需要显示展开图标的列
          type: "expand",
          title: "",
          width: 50,
          align: "center",
        },
        {
          field: "name",
          key: "name",
          title: "部门名称",
          align: "left",
        },
        {
          field: "userCount",
          key: "userCount",
          title: "总人数",
          align: "center",
         
        },
        {
          field: "hasSigninA",
          key: "hasSigninA",
          title: "上班打卡",
          align: "center",
        },
        {
          field: "hasSigninB",
          key: "hasSigninB",
          title: "下班打卡",
          align: "center",
        },
       
      ],
      tableData: [],
      
    };
  },
  components: {
   
    ChildVetable,
  },
  computed: {},
  created() {
     
      setTimeout(() => {
           var date = new Date();
            this.onConfirm2(date)
        }, 300);
  },
  //此钩子函数会反复触发,是keep-alive特有的钩子函数，取滚动高度
  activated() {
    this.isShowSelectTemplate = false;
    this.$refs.wrapper.scrollTop = this.scroll || 0;
  },
  //路由离开时的钩子函数,存储滚动高度
  beforeRouteLeave(to, from, next) {
    this.scroll = this.$refs.wrapper.scrollTop || 0;
    next();
  },

  updated() {},
  watch: {},
  mounted() {},
  methods: {
    async onConfirm(date) {
      this.showcalendar = false;
      this.date = this.formatDate(date);

       this.showLoadingTable();
     
      var d = await getDepartmentData(this.date);
      if (d.data.success) {
        this.tableData = d.data.data;
      } else if (d.data.errorMessage) {
        Toast(d.data.errorMessage);
      }
      this.hideLoadingTable();
    },

    async onConfirm2(date) {
     
      this.date = this.formatDate(date);
       this.showLoadingTable();
      var d = await getDepartmentData(this.date);
      if (d.data.success) {
        this.tableData = d.data.data;
      } else if (d.data.errorMessage) {
        Toast(d.data.errorMessage);
      }
      this.hideLoadingTable();
    },

    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },


    showLoadingTable() {
      if (!this.loadingInstance) {
        this.loadingInstance = VeLoading({
          target: document.querySelector("#tb"),
          // 等同于
          // target:"#loading-container"
          name: "wave",
        });
      }

      this.loadingInstance.show();
    },
    hideLoadingTable() {
      this.loadingInstance.close();
    },
 
  },
};
</script>

<style lang="less" scoped>
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}
</style>