import { render, staticRenderFns } from "./DepartmentData.vue?vue&type=template&id=df7da176&scoped=true&"
import script from "./DepartmentData.vue?vue&type=script&lang=js&"
export * from "./DepartmentData.vue?vue&type=script&lang=js&"
import style0 from "./DepartmentData.vue?vue&type=style&index=0&id=df7da176&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df7da176",
  null
  
)

export default component.exports