<template>
  <div class="child-vetable">
    <ve-table id="tb" :columns="columns" :table-data="tableData" />
  </div>
</template>
<script>
import { getUserData } from "@/api/workSignin";
export default {
  name: "childVetable",
  props: {
    row: Object,
    timeRange: String,
  },
  emits: ["beforeLoadData","finishedLoadData",],
  data() {
    return {
      columns: [
        { field: "trueName", key: "trueName", title: "姓名", width: "70px" },
       
        { field: "signinTimeA", key: "signinTimeA", title: "上班打卡", renderBodyCell: ({ row }) => {
            return (
              <span
              >
                {this.getTime(row.signinTimeA)}
              </span>
            );
          }, },
        { field: "signinTimeB", key: "signinTimeB", title: "下班打卡", renderBodyCell: ({ row }) => {
            return (
              <span
              >
                {this.getTime(row.signinTimeB)}
              </span>
            );
          },  },
           { field: "beginImageUrl", key: "beginImageUrl", title: "上班图片", renderBodyCell: ({ row }) => {
            return (
             <van-image
                    lazy-load
                    class="w100"
                    fit="contain"
                    style="max-height: 200px"
                    src={row.beginImageUrl}
                  />
            );
          },  },
           { field: "endImageUrl", key: "endImageUrl", title: "下班图片", renderBodyCell: ({ row }) => {
            return (
               <van-image
                    lazy-load
                    class="w100"
                    fit="contain"
                    style="max-height: 200px"
                    src={row.endImageUrl}
                  />
            );
          },  },
      ],
      tableData: [],
    };
  },
  created() {
    this.onload();
  },
  updated() {
    this.addpreview();
  },
  methods: {

    onload() {
      if (this.row && this.row.name && this.tableData.length <= 0) {
        this.$emit("beforeLoadData")
        getUserData(
          this.row.name,
         this.timeRange
        ).then((d) => {
          this.$emit("finishedLoadData")
          if (d.data.success) {
            this.tableData = d.data.data;
          } else if (d.data.errorMessage) {
            alert(d.data.errorMessage);
          }
        });
      }
    },
    getTime(date){
        if(date){
            return date.substring(11,16)
        }
        return "-"
    },
     //加图片预览
    addpreview() {
      var listdiv = document.getElementsByClassName("child-vetable");
      listdiv.forEach((acontentdiv) => {
        var imgs = acontentdiv.getElementsByTagName("img");

        imgs.forEach((element) => {
          element.setAttribute("preview", this.row.name);
        });
      });
      this.$previewRefresh();
    },
  },
};
</script>
<style>
.child-vetable {
}
</style>